import React from "react";
import * as styles from "./Footer.module.css";
import { Link } from "gatsby";
import { HoursText } from "../../../lib/useOpenHours";
import { CookieBanner } from "../../blocks/CookieBanner/CookieBanner";
import { FooterNavigation } from "../../../lib/useFooter";
import { RichTextElement } from "@kontent-ai/gatsby-components";
import { KontentLink } from "../../../types";
import { formatLinks } from "../../../lib/links";
import { RichTextSemanticLinks } from "../../elements/RichTextSemanticLinks/RichTextSemanticLinks";

interface Props {
  libraryHours: HoursText[];
  navigation?: FooterNavigation;
}

export const Footer: React.FC<Props> = ({ libraryHours, navigation }) => {
  const currentYear = new Date().getFullYear();

  return (
    <section className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.infoColumn}>
          <div className={styles.infoItem}>
            <h3>Library Hours</h3>
            <div>
              {libraryHours?.map((hours, idx) => (
                <div className={styles.hours} key={idx}>
                  <p>
                    {hours.label}
                    <br />
                    {hours.hours}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.infoItem}>
            <h3>Telephone</h3>
            <a href="tel:8163634600">816.363.4600</a>
          </div>
          <div className={styles.infoItem}>
            <h3>Address</h3>
            <address>
              5109 Cherry Street
              <br />
              Kansas City, Missouri
              <br />
              64110-2498
            </address>
          </div>
        </div>
        {navigation?.links?.modular_content?.length && (
          <RichTextSemanticLinks
            content={navigation.links}
            classes={styles.linkColumns}
          />
        )}
        <ul className={styles.socialColumn}>
          {navigation?.facebookUrl && (
            <li>
              <a href={navigation?.facebookUrl} target="_blank">
                <svg>
                  <use xlinkHref="/icons/facebook.svg#img"></use>
                </svg>
                <span className="vis-hidden">
                  Linda Hall Library on Facebook
                </span>
              </a>
            </li>
          )}
          {navigation?.twitterUrl && (
            <li>
              <a href={navigation.twitterUrl} target="_blank">
                <svg>
                  <use xlinkHref="/icons/twitter.svg#img"></use>
                </svg>
                <span className="vis-hidden">
                  Linda Hall Library on Twitter
                </span>
              </a>
            </li>
          )}
          {navigation?.tumblrUrl && (
            <li>
              <a href={navigation.tumblrUrl} target="_blank">
                <svg>
                  <use xlinkHref="/icons/tumblr.svg#img"></use>
                </svg>
                <span className="vis-hidden">Linda Hall Library on Tumblr</span>
              </a>
            </li>
          )}
          {navigation?.vimeoUrl && (
            <li>
              <a href={navigation.vimeoUrl} target="_blank">
                <svg>
                  <use xlinkHref="/icons/vimeo.svg#img"></use>
                </svg>
                <span className="vis-hidden">Linda Hall Library on Vimeo</span>
              </a>
            </li>
          )}
          {navigation?.instagramUrl && (
            <li>
              <a href={navigation.instagramUrl} target="_blank">
                <svg>
                  <use xlinkHref="/icons/instagram.svg#img"></use>
                </svg>
                <span className="vis-hidden">
                  Linda Hall Library on Instagram
                </span>
              </a>
            </li>
          )}
          {navigation?.youtubeUrl && (
            <li>
              <a href={navigation.youtubeUrl} target="_blank">
                <svg>
                  <use xlinkHref="/icons/youtube.svg#img"></use>
                </svg>
                <span className="vis-hidden">
                  Linda Hall Library on Youtube
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className={styles.copyright}>
        &copy;{currentYear}, Linda Hall Library, All Rights Reserved
      </div>
      <CookieBanner />
    </section>
  );
};
